<script>
import { mapFields } from "vuex-map-fields";
import filters from '@/filters.js';

export default {
    filters: {
        notBlank: filters.notBlank,
        date: filters.date,
        yesNo: filters.yesNo
    },
    data() {
        return {
            loading: false,
            crmInfo: []
        }
    },
    setup() {
        return {
            icons: {
            },
        }
    },

    async mounted() {
        try {
            this.loading = true;
            this.crmInfo = await this.$store.state.CRMData;
        } catch (error) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    },
    methods: {

    },
    computed: {
        ...mapFields([
            "CRMData"
        ]),
    },
}
</script>

<template>
    <v-card  outlined elevation="24">
        <v-row>
            <v-col>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td class="leftCol">Is Certificate</td>
                                <td class="rightCol">{{ crmInfo.isCertificate | yesNo }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Is Staff</td>
                                <td class="rightCol">{{ crmInfo.isStaff | yesNo }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Is TRBC Member</td>
                                <td class="rightCol">{{ crmInfo.isTrbc | yesNo }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Is Donor</td>
                                <td class="rightCol">{{ crmInfo.isDonor | yesNo }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Is Student / Applicant</td>
                                <td class="rightCol">{{ crmInfo.isStudent | yesNo }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col style="float:left">
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td class="leftCol">Gender</td>
                                <td class="rightCol">{{ crmInfo.gender | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Marital Status</td>
                                <td class="rightCol">{{ crmInfo.maritalStatus | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Ethnicity</td>
                                <td class="rightCol">{{ crmInfo.ethnicity | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Citizenship</td>
                                <td class="rightCol">{{ crmInfo.citizenship | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Prior School</td>
                                <td class="rightCol">{{ crmInfo.priorSchool | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Resident Living Abroad</td>
                                <td class="rightCol">{{ crmInfo.residentLivingAbroad | yesNo }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Interest Level</td>
                                <td class="rightCol">{{ crmInfo.visa | notBlank }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td class="leftCol">Date Of Death</td>
                                <td class="rightCol">{{ crmInfo.suffix | date }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Contact Type</td>
                                <td class="rightCol">{{ crmInfo.type | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Religion</td>
                                <td class="rightCol">{{ crmInfo.religion | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Military Indicator</td>
                                <td class="rightCol">{{ crmInfo.militaryInd | yesNo }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Military Branch</td>
                                <td class="rightCol">{{ crmInfo.militaryBranch | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Military Status</td>
                                <td class="rightCol">{{ crmInfo.militaryStatus | notBlank }}</td>
                            </tr>
                            <tr>
                                <td class="leftCol">Veteran Status</td>
                                <td class="rightCol">{{ crmInfo.veteranStatus | notBlank }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card>
</template>

<style scoped></style>