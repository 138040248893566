<script>
import { Ferpa } from 'aether-ferpa-sprapin-vue2';
import { Sprapin } from 'aether-ferpa-sprapin-vue2';
import ExtraDetails from '@/views/pages/ExtraDetails.vue';
import { mapFields } from "vuex-map-fields";

export default {
    components: { Ferpa, Sprapin, ExtraDetails },
    data() {
        return {
            loading: false,
            crmInfo: [],
            showSprapin: false
        }
    },
    computed: {
        ...mapFields([
            "userBU"
        ]),
    },
    async mounted() {
        const userBU = this.$store.state.userBU;
        if (userBU === 'Resident Graduate Admissions') {
            this.showSprapin = true;
        }
    },
}
</script>

<template>
    <div>
        <ExtraDetails />
        <br />
        <Ferpa />
        <br />
        <Sprapin v-show="this.showSprapin" />
    </div>
</template>